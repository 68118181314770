import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { get, merge } from 'lodash';

// Material
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// Tubular
import { ToolbarOptions } from 'tubular-react';
import { useTbTable } from 'tubular-react-common';
import DataGrid from '../utils/DataGrid';
import columns from './InventoryColumns';

import Wrapper from '../Wrapper';
import InventoryUpdateQuantity from './InventoryUpdateQuantity';
import InventoryMapProduct from './InventoryMapProduct';

import metrc from './apiClient';

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const ITEMS_PER_PAGE = 25;

const useStyles = makeStyles({
  alert: {
    position: 'fixed',
    right: 20,
    top: 20,
    zIndex: 9999999
  }
});

const Inventory = ({ user }) => {
  const classes = useStyles();
  const [logReasons, setLogReasons] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [alert, setAlert] = useState(null);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [forceFetchData, setForceFetchData] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const tbTableInstance = useTbTable(columns, inventory, {
    componentName: 'metrcInventory',
    pagination: {
      itemsPerPage: ITEMS_PER_PAGE,
      page: 0
    }
  });

  const toggleModal = product => {
    setSelectedProduct(product);
  };

  const onCloseModal = () => {
    setSelectedProduct(null);
    setForceFetchData(!forceFetchData);
  };

  const showAlert = alertProps => {
    setAlert(alertProps);
    setTimeout(() => {
      setAlert(null);
    }, 6000);
  };

  const processList = item => ({
    ...item,
    product_name_component: (() => (
      <a href={`/products/provider_id/${item.product_id}`}>
        {Array.isArray(item.product_name)
          ? item.product_name.join(', ')
          : item.product_name}
      </a>
    ))(),
    transfer_id_component: (() => (
      <a href={`/metrc/intake/delivery/${item.transfer_id}`}>
        {item.transfer_id}
      </a>
    ))(),
    actions: (() => (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => toggleModal(item)}
          style={{ marginRight: 6, marginBottom: 6 }}
        >
          Update Quantity
        </Button>
      </div>
    ))()
  });

  const fetchData = async () => {
    setIsLoading(true);
    const {
      data: { list, log_reasons: reasons, pages }
    } = await metrc.logs({ page: page + 1, search: searchValue });
    setInventory(list.map(processList));
    setLogReasons(reasons);
    setCount(pages * ITEMS_PER_PAGE);
    setIsLoading(false);
  };

  const onSearchChange = ({ target }) => {
    debounce(() => setSearchValue(target.value), 600)();
  };

  const toolbarOptions = new ToolbarOptions({
    title: 'Inventory',
    searchText: false,
    rowsPerPageOptions: [],
    customItems: [
      <TextField placeholder="Search..." onChange={onSearchChange} />
    ]
  });

  useEffect(() => {
    fetchData();
  }, [page, searchValue]);

  const onChangePage = newPage => {
    setPage(newPage);
  };

  const onUpdateQuantity = () => {
    showAlert({ severity: 'success', msg: 'Product quantity updated' });
    fetchData();
  };

  return (
    <Wrapper>
      {!!alert && (
        <Alert className={classes.alert} severity={alert.severity}>
          {alert.msg}
        </Alert>
      )}
      <DataGrid
        gridName="metrcInventory"
        tbTableInstance={tbTableInstance}
        loading={isLoading}
        toolbarOptions={toolbarOptions}
        count={count}
        page={page}
        onChangePage={onChangePage}
      />
      {!!selectedProduct && (
        <InventoryUpdateQuantity
          logReasons={logReasons}
          product={selectedProduct}
          onClose={onCloseModal}
          submit={onUpdateQuantity}
          user={user}
          showAlert={showAlert}
        />
      )}
    </Wrapper>
  );
};

export default Inventory;
