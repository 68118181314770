import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import metrc from './apiClient';

const styles = {
  form: {
    width: 160,
    marginLeft: 16
  }
};

function UpdateQuantityForm({ metrcLabel, userId, productId }) {
  const [enteredQuantity, setEneteredQuantity] = useState('');
  const handleSubmit = async e => {
    e.preventDefault();

    await metrc.updateQuantityByLabel({
      productId,
      metrcLabel,
      quantity: enteredQuantity,
      changedBy: userId
    });

    location.reload();

    return false;
  };

  return (
    <div>
      <form onSubmit={handleSubmit} style={styles.form}>
        <TextField
          value={enteredQuantity}
          onChange={e => setEneteredQuantity(e.target.value)}
          variant="filled"
          size="small"
          margin="dense"
          type="number"
          label="Input Quantity"
          inputProps={{
            step: '0.01',
            min: 0
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" type="submit">
                  <CheckIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </form>
    </div>
  );
}

UpdateQuantityForm.defaultProps = {};

UpdateQuantityForm.propTypes = {
  metrcLabel: PropTypes.string.isRequired
};

export default UpdateQuantityForm;
