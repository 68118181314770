import { ColumnModel, ColumnDataType } from 'tubular-common';
import { capitalize } from 'lodash';

const defaultColumnOptions = {
  searchable: false,
  filterable: false,
  sortable: false
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    sortable: false,
    ...defaultColumnOptions,
    ...options
  });
}

export default [
  createColumnModel('transfer_id_comp', 'Manifest #'),
  createColumnModel('label', 'Metrc Label'),
  createColumnModel('quantity_formatted', 'Quantity'),
  createColumnModel('unit_of_measure_name', 'Unit of Measure'),  
  createColumnModel('changed_by_formatted', 'Changed By'),
  createColumnModel('reason'),
  createColumnModel('created_at', 'Received Date', 'DateTime'),
  createColumnModel('expiration_date', 'Expiration Date', 'DateTime')
];
