import { ColumnModel, ColumnDataType } from 'tubular-common';
import { capitalize } from 'lodash';

const defaultColumnOptions = {
  searchable: false,
  filterable: false,
  sortable: true
};

function createColumnModel(name, label, type, options = {}) {
  return new ColumnModel(name, {
    label: label || capitalize(name),
    dataType: ColumnDataType[type],
    ...defaultColumnOptions,
    ...options
  });
}

export default [
  createColumnModel('status_component', 'Status'),
  createColumnModel('id', '#', 'Number'),
  createColumnModel('received_date_time', 'ETA', 'DateTime'),
  createColumnModel('package_count', 'Packages', 'Number'),
  createColumnModel('shipper_facility_name', 'Shipper Facility'),
  createColumnModel('actions', 'Actions')
];
