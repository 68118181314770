import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';


const ExpireDatePicker = ({
  expireDate,
  expireDateAs,
  onChange,
}) => {
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              format="MM-dd-Y"
              value = {expireDate ? moment(expireDate).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY') }
              clearable
              onChange={(date) => onChange({ [expireDateAs]: date })}
              autoOk
            />
      </MuiPickersUtilsProvider>
    </div>
  );
};

ExpireDatePicker.propTypes = {
  expireDate: PropTypes.instanceOf(Date).isRequired,
  expireDateAs: PropTypes.string,
  onChange: PropTypes.func
};

ExpireDatePicker.defaultProps = {
  onChange: () => {},
  expireDateAs: 'expireDate',
  startProps: {},
};

export default ExpireDatePicker;
