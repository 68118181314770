import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { startOfDay, endOfDay } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';

import metrc from './apiClient';

import InventoryIntake from './InventoryIntake';
import Reports from './Reports';

import Alert from '../utils/Alert'

// eslint-disable-next-line react/prop-types
function TabPanel({ children, value, index, ...rest }) {
  return index === value ? (
    <Paper
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {children}
    </Paper>
  ) : null;
}

const Metrc = ({ selectedTab }) => {
  const [alert, setAlert] = useState({})
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const changeDate = (date) => {
    setDateRange((state) => ({
      ...state,
      startDate: date.startDate ? startOfDay(date.startDate) : state.startDate,
      endDate: date.endDate ? endOfDay(date.endDate) : state.endDate,
    }));
  };
  const onDateChangeIntake = (value) => {
    const date = { ...value };
    if (value.startDate) date.endDate = addDays(value.startDate, 1);
    else date.startDate = subDays(value.endDate, 1);

    changeDate(date);
  };

  const onDateChangeReports = (date) => changeDate(date);

  const onTabChange = (e, value) => {
    window.location.href = `/metrc/${value}`;
  };

  const onSyncClick = async () => {
    await metrc.syncInventory({ dateRange });
    setAlert({severity: 'success', message: 'Successfully Synced'})
  };

  return (
    <div>
      <Alert message={alert.message} severity={alert.severity} clear={() => setAlert({})} />
      <Grid container justify="space-between">
        <Grid item xs>
          <Tabs onChange={onTabChange} value={selectedTab}>
            <Tab value="intake" label="Inventory Intake" />
            <Tab value="reports" label="Reporting Sales" />
          </Tabs>
        </Grid>
        <Grid item xs={0}>
          <Button
            style={{ marginLeft: 16, marginRight: 16 }}
            onClick={onSyncClick}
            variant="contained"
            color="secondary"
          >
            Sync Metrc
          </Button>
        </Grid>
      </Grid>
      <TabPanel value={selectedTab} index="intake">
        <InventoryIntake
          onDateRangeChange={onDateChangeIntake}
          dateRange={dateRange}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index="reports">
        <Reports
          onDateRangeChange={onDateChangeReports}
          dateRange={dateRange}
        />
      </TabPanel>
    </div>
  );
};

Metrc.propTypes = {
  selectedTab: PropTypes.string.isRequired,
};

export default Metrc;
