import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ToolbarOptions } from 'tubular-react';
import { useTbTable } from 'tubular-react-common';

import { get } from 'lodash';

import Button from '@material-ui/core/Button';

import DataGrid from '../utils/DataGrid';
import columns from './InventoryIntakeColumns';
import Wrapper from '../Wrapper';
import RangeDatePicker from '../utils/Datepicker';

import metrc from './apiClient';

import Alert from '../utils/Alert';

import '../styles/IntakeInventory.css';

import socket, { subscribeToInventory } from './socket';

const licenseNumber = get(gon, 'store.metrc_license_number');

const DeliveryStatus = ({ delivery }) => {
  const succeed = !!delivery.received_date_time;

  const styles = {
    width: 25,
    height: 25,
    borderRadius: 30,
    margin: 5,
    backgroundColor: succeed ? 'green' : 'red',
  };

  return <div style={styles} />;
};

const processData = (item) => ({
  ...item,
  received_date_time: item.received_date_time || '-',
  status_component: (() => <DeliveryStatus delivery={item} />)(),
  actions: (() => {
    return (
      <Button
        variant="outlined"
        color="primary"
        href={`/metrc/intake/delivery/${item.id}`}
      >
        View Details
      </Button>
    );
  })(),
});

const ITEMS_PER_PAGE = 25;

const InventoryIntake = ({ onDateRangeChange, dateRange }) => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState({ message: null, severity: 'success' });

  const tbTableInstance = useTbTable(columns, tableData, {
    pagination: {
      itemsPerPage: ITEMS_PER_PAGE,
      page: 0,
    },
  });

  const toolbarOptions = new ToolbarOptions({
    title: 'Inventory',
    rowsPerPageOptions: [],
    itemsPerPage: ITEMS_PER_PAGE,
    advancePagination: false,
    searchText: false,
    topPager: false,
    customItems: [
      <RangeDatePicker
        onChange={onDateRangeChange}
        selectedStartDate={dateRange.startDate}
        selectedEndDate={dateRange.endDate}
      />,
    ],
  });

  async function fetchData() {
    const {
      data: { list, pages },
    } = await metrc.inventory({
      licenseNumber,
      dateRange,
      page: page + 1,
    });
    setTableData(list.map(processData));
    setCount(ITEMS_PER_PAGE * pages);
  }

  useEffect(() => {
    fetchData();
  }, [dateRange, page]);

  useEffect(() => {
    subscribeToInventory(async () => {
      await fetchData();
      setAlert({ severity: 'success', message: 'New Inventory' });
    });
  }, []);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Wrapper>
      <Alert
        message={alert.message}
        severity={alert.severity}
        clear={() => setAlert({})}
      />
      <DataGrid
        gridName="stores"
        columns={columns}
        toolbarOptions={toolbarOptions}
        tbTableInstance={tbTableInstance}
        count={count}
        page={page}
        onChangePage={onPageChange}
      />
    </Wrapper>
  );
};

InventoryIntake.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default InventoryIntake;
