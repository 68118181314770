import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import metrc from './apiClient';

const attributes = [
  {
    label: 'Metrc ID',
    key: 'Id'
  },
  {
    label: 'Metrc Label',
    key: 'Label'
  },
  {
    label: 'Metrc Received',
    key: 'ReceivedDate'
  },
  {
    label: 'Canalogistics ID',
    key: ''
  },
  {
    label: 'Canalogistics Received',
    key: ''
  }
];

const useStyles = makeStyles({
  brandFormGroup: {
    alignItems: 'center'
  }
});

const Product = ({ packageId }) => {
  const [product, setProduct] = useState(null);
  const [brands, setBrands] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const theme = useTheme();
  const classes = useStyles({ theme });
  async function fetchProduct() {
    // const { data } = await metrc.get(`packages/v1/${packageId}`);
    // setProduct(data);
  }
  async function fetchBrands() {
    const { data } = await axios.get('/api/brands');
    setBrands(data);
  }
  useEffect(() => {
    fetchProduct();
    fetchBrands();
  }, []);

  const handleBrandChange = (e, value) => {
    setSelectedBrand(value);
  };

  const handleGoBack = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('deliveryId'))
      window.location.href = `/metrc/inventory/delivery/${params.get(
        'deliveryId'
      )}`;
    else window.location.href = '/metrc/inventory';
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div>
          <Button
            style={{ marginBottom: 16 }}
            startIcon={<ArrowBackIcon />}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </div>
        {!product ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Product Summary
                    </Typography>
                    {attributes.map(attr => (
                      <>
                        <Typography variant="body1">
                          {attr.label}:<> </>{' '}
                          <strong>{product[attr.key]}</strong>
                        </Typography>
                      </>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Actions
                    </Typography>
                    <FormGroup row classes={{ root: classes.brandFormGroup }}>
                      <FormControl>
                        <Autocomplete
                          options={brands}
                          getOptionLabel={option => option.name}
                          style={{ width: 300 }}
                          onChange={handleBrandChange}
                          value={selectedBrand}
                          renderInput={params => (
                            <TextField {...params} label="Select Brand" />
                          )}
                        />
                      </FormControl>
                      <FormControl>
                        <Button>Assign Brand</Button>
                      </FormControl>
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

Product.propTypes = {
  packageId: PropTypes.string.isRequired
};

export default Product;
