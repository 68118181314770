import axios from 'axios';
import { get } from 'lodash';
import { addDays, formatISO } from 'date-fns';

function objectToQuerystring(obj) {
  let i = 0;
  return Object.keys(obj).reduce(function(str, k) {
    const delimiter = i === 0 ? '?' : '&';
    const key = encodeURIComponent(k);
    const val = encodeURIComponent(obj[key]);
    if (!val) return str;

    i += i;
    return [str, delimiter, key, '=', val].join('');
  }, '');
}

const TOKEN = get(gon, 'metrc_config.user_token');
const apiUrl = get(gon, 'metrc_config.api_url');
const defaultLicenseNumber = get(gon, 'store.metrc_license_number');
const metrcKey = get(gon, 'store.metrc_key');

const metrc = axios.create({
  baseURL: `${apiUrl}/metrc/v1`,
  headers: {
    Authorization: TOKEN,
    'X-Metrc-Key': metrcKey
  }
});

const inventory = ({ licenseNumber = defaultLicenseNumber, page }) =>
  metrc.get(`dashboard/inventory/${licenseNumber}/${page}`);

const syncInventory = ({
  licenseNumber = defaultLicenseNumber,
  dateRange: { startDate, endDate }
}) =>
  metrc.get(
    `dashboard/sync/${licenseNumber}/${formatISO(startDate)}/${formatISO(
      addDays(startDate, 1)
    )}`
  );

const inventoryDetails = ({
  licenseNumber = defaultLicenseNumber,
  transferId
}) => metrc.get(`/dashboard/inventory/details/${licenseNumber}/${transferId}`);

const updateCategory = ({
  licenseNumber = defaultLicenseNumber,
  packs,
  categories
}) =>
  metrc.post(`/dashboard/inventory-details/update-category/${licenseNumber}`, {
    categories,
    packs
  });

const reports = ({
  page = 1,
  licenseNumber = defaultLicenseNumber,
  filters: { successful, failed } = {},
  dateRange: { startDate, endDate } = {}
} = {}) =>
  metrc.get(
    `/dashboard/reports/${successful ? 1 : 0}/${failed ? 1 : 0}/${formatISO(
      startDate
    )}/${formatISO(endDate)}/${page}/${licenseNumber}`
  );

const logs = ({
  page = 1,
  licenseNumber = defaultLicenseNumber,
  search
} = {}) =>
  metrc.get(
    `dashboard/logs/${page}/${licenseNumber}${objectToQuerystring({
      q: search
    })}`
  );

const updateQuantity = ({
  productId,
  reasonId,
  quantity,
  changedBy,
  label,
  licenseNumber = defaultLicenseNumber
} = {}) =>
  metrc.post(`dashboard/logs-update-quantity/${licenseNumber}`, {
    product_id: productId,
    reason_id: reasonId,
    quantity,
    changed_by: changedBy,
    label
  });

const productLogs = ({ productId, licenseNumber = defaultLicenseNumber }) =>
  metrc.get(`dashboard/logs-single/${productId}/${licenseNumber}`);

const getProductLabel = ({
  productId,
  quantity,
  licenseNumber = defaultLicenseNumber
}) =>
  metrc.post(`dashboard/logs-labels/${licenseNumber}`, {
    product_id: productId,
    quantity
  });

const addQuantity = ({
  productId,
  quantity,
  licenseNumber = defaultLicenseNumber
}) =>
  metrc.post(`dashboard/add-quantity/${licenseNumber}`, {
    package_id: productId,
    quantity
  });

  const addExpireDate = ({
    productId,
    productLabel,
    expireDate,
    licenseNumber = defaultLicenseNumber
  }) =>
    metrc.post(`dashboard/add-expier-date/${licenseNumber}`, {
      package_id: productId,
      productLabel: productLabel,
      expireDate: expireDate
    });

const processMapping = ({ licenseNumber = defaultLicenseNumber } = {}) =>
  metrc.post(`/prods-map/process/${licenseNumber}`);

const processCovaMapping = ({ licenseNumber = defaultLicenseNumber } = {}) =>
  metrc.get(`/prods-map/${licenseNumber}`);

const addMapping = ({ fromProductId, toProductIds }) =>
  metrc.post(`prods-map/add/${defaultLicenseNumber}`, {
    product_id: fromProductId,
    products_ids: toProductIds
  });

const updateQuantityByLabel = ({
  productId,
  metrcLabel,
  quantity,
  changedBy,
  licenseNumber = defaultLicenseNumber
}) =>
  metrc.post(`prods-map/update-quantity/${licenseNumber}`, {
    product_id: productId,
    metrc_label: metrcLabel,
    quantity,
    changed_by: changedBy
  });

export default {
  client: metrc,
  inventory,
  syncInventory,
  inventoryDetails,
  updateCategory,
  reports,
  logs,
  updateQuantity,
  productLogs,
  getProductLabel,
  addQuantity,
  addExpireDate,
  processMapping,
  processCovaMapping,
  addMapping,
  updateQuantityByLabel
};
