import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import metrc from './apiClient';

const styles = {
  form: {
    width: 160,
    marginLeft: 16
  }
};

function UpdateProductExpierDate({ packageId, productLabel, expireDate }) {
  //const [enteredExpierDate, setEnteredExpierDate] = useState('');
  const handleOnChange = async date => {
console.log("Selected date", date);
    await metrc.addExpireDate({
      productId: packageId,
      productLabel: productLabel,
      expireDate: moment(date).format('MM/DD/YYYY'),
    });

    location.reload();

    return false;
  };

  return (
    <div style={styles.form} >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              format="MM-dd-Y"
              value = {expireDate ? moment(expireDate).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY') }
              clearable
              onChange={(date) => handleOnChange(date)}
              autoOk
            />
      </MuiPickersUtilsProvider>
    </div>
  );
}

UpdateProductExpierDate.propTypes = {
  expireDate: PropTypes.instanceOf(Date).isRequired,
  expireDateAs: PropTypes.string,
  onChange: PropTypes.func
};

UpdateProductExpierDate.defaultProps = {
  onChange: () => {},
  expireDateAs: 'expireDate',
  startProps: {},
};

export default UpdateProductExpierDate;
